<template>
    <div class="stepsdemo-content">
        <Card>
            <!-- <template v-slot:title>
                Price Details
            </template>
            <template v-slot:subtitle>
                Enter your pricing details
            </template> -->
            <template v-slot:content>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <div class="grid" v-for="(item, Sindex) in valid$.collection.$model" :key="Sindex">
                            <div class="col-12">
                                <Fieldset :legend="'Day ' + item.day" >
                                    <div class="grid mb-3" v-for="(activity, index)  in item.activities" :key="index" style="border: 1px solid #DEE2E6;">
                                        <div class="col-3 lg:col-3">
                                            <div class="field mb-0">
                                                <label for="time">Time <span style="color: #ff4444">*</span></label>
                                                <Calendar
                                                    :class="{'p-invalid': valid$.collection.$each.$response.$data[Sindex].activities.$each.$data[index].time.$invalid && submitted}"
                                                    id="time"
                                                    v-model="activity.time"
                                                    :timeOnly="true"
                                                    hourFormat="12"
                                                    :showIcon="true"
                                                />
                                                <span v-if="valid$.collection.$each.$response.$data[Sindex].activities.$each.$data[index].time.$invalid && submitted">
                                                    <span
                                                        id="time-error"
                                                        v-for="(error, index) of valid$.collection.$each.$response.$errors[Sindex].activities[0].$response.$errors[index].time"
                                                        :key="index"
                                                    >
                                                        <small class="p-error">{{error.$message.replace("Value", "Time")}}</small>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-9 lg:col-9">
                                            <div class="field mb-0">
                                                <label for="title">Title <span style="color: #ff4444">*</span></label>
                                                <div class="p-inputgroup">
                                                    <InputText
                                                        :class="{'p-invalid': valid$.collection.$each.$response.$data[Sindex].activities.$each.$data[index].title.$invalid && submitted}"
                                                        id="title"
                                                        type="text"
                                                        v-model="activity.title"
                                                        style="float: left"
                                                    />
                                                    <Button
                                                        label="Remove"
                                                        v-if="item.activities.length > 1"
                                                        @click="removeActivities(Sindex, index)"
                                                        icon="pi pi-times"
                                                        class="p-button p-button-danger"
                                                        style="margin-left: 5px"
                                                    />
                                                </div>
                                                <span v-if="valid$.collection.$each.$response.$data[Sindex].activities.$each.$data[index].title.$invalid && submitted">
                                                    <span
                                                        id="title-error"
                                                        v-for="(error, index) of valid$.collection.$each.$response.$errors[Sindex].activities[0].$response.$errors[index].title"
                                                        :key="index"
                                                    >
                                                        <small class="p-error">{{error.$message.replace("Value", "Title")}}</small>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-12">
                                            <div class="field">
                                                <label for="description">Description <span style="color: #ff4444">*</span></label>
                                                <Textarea
                                                    :class="{'p-invalid': valid$.collection.$each.$response.$data[Sindex].activities.$each.$data[index].description.$invalid && submitted}"
                                                    id="description"
                                                    type="text"
                                                    v-model="activity.description"
                                                    :autoResize="true"
                                                    rows="1"
                                                    cols="30"
                                                />
                                                <span v-if="valid$.collection.$each.$response.$data[Sindex].activities.$each.$data[index].description.$invalid && submitted">
                                                    <span
                                                        id="description-error"
                                                        v-for="(error, index) of valid$.collection.$each.$response.$errors[Sindex].activities[0].$response.$errors[index].description"
                                                        :key="index"
                                                    >
                                                        <small class="p-error">{{error.$message.replace("Value", "Description")}}</small>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Button v-if="valid$.collection.$model.length > 1" class="p-button p-button-danger"  @click="removeSchedule(Sindex)" label="Remove Day" style="width: 150px;"/>
                                    <Button class="p-button-info" @click="addActivities(Sindex)" label="New Activitie" style="width: 150px; float: right"/>
                                </Fieldset>
                            </div>                           
                            <div class="col-12 lg:col-12">
                                <Button class="p-button-info" v-if="Sindex == valid$.collection.$model.length-1" @click="addSchedule(Sindex)" label="Add New Day" style="width: 150px; float: right;"/>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex justify-content-between flex-wrap mb-4">
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" style="width:200px"></Button>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Next" @click="nextPage(!valid$.$invalid)" icon="pi pi-angle-right" icon-pos="right" style="width:200px"></Button>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { reactive, ref, toRef } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Axios from 'axios';

export default {
    props: {
        slug: String
    },
    setup(props){
        const slug = toRef(props, 'slug');

        const submitted = ref(false);

        const rules = {
            collection: {
                $each: helpers.forEach({
                    day: { required },
                    activities: {
                        $each: helpers.forEach({
                            time: { required },
                            title: { required },
                            description: {required}
                        })
                    }
                })
            },
        }
        const state = reactive({
            collection: [
                { day: 1 , 
                activities: 
                    [{time: '',
                    title:'',
                    description: ''}
                    ]
                }
            ]
        })

       const getSingleTour = () => {
            Axios
            .get("api/tour/view/"+slug.value)
            .then((res) => {
                if(res.data.response=="success") {
                    state.collection = res.data.data.schedule;
                }
                else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
        getSingleTour();

        const valid$ = useVuelidate (rules, state);

        const addActivities = (index) => {
            state.collection[index].activities.push(
                {time: '',
                title: '',
                description:''
                }
            );
        };
        const removeActivities = (Sindex, index) => {
            state.collection[Sindex].activities.splice(index, 1);
            
        };
        const addSchedule = (Sindex) => {
            state.collection.push(
                {day: Sindex+2,
                activities:[
                    {time: '',
                    title: '',
                    description:''
                    }
                ]}
            );
        };
        const removeSchedule = (Sindex) => {
            if(state.collection.length > Sindex+1){
                for( var i = Sindex+1; i< state.collection.length; i++){
                    state.collection[i].day = i;}
                }            
            state.collection.splice(Sindex, 1);
        };

        return{
            valid$,
            state,
            submitted,
            addActivities,
            addSchedule,
            removeActivities,
            removeSchedule,
        }
    },
    methods: {
        nextPage(isFormValid) {
            this.submitted = true;
            if (!isFormValid) {
                return;
            }
            this.$emit('next-page', {formData: {
                schedule: this.state.collection
            }, 
            pageIndex: 1});
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        }
    }
}
</script>

<style>

</style>